import React from "react";
import { FormErrorsTypes, FormNamesTypes } from "@utils/types";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";

type FormFieldProps = {
  errors: FormErrorsTypes,
  name: FormNamesTypes
};

const Error = styled.span`
  animation: fadeIn ease .3s;
  ${tw`absolute bottom-2 right-0 text-xs text-error leading-20 -tracking-018`}
`;

const FormError = ({ errors, name }: FormFieldProps) => {
  const { t } = useTranslation();

  return (
    <>
      { errors[name] && (
        <Error>
          {t(`Form:Errors:${name}:${errors[name].message ? errors[name].message : errors[name].type}`)}
        </Error>
      )}
    </>
  );
};

export default FormError;
