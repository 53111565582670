import React from "react";
import { FormFieldProps } from "@utils/types";
import { useIntl } from 'gatsby-plugin-intl';
import styled from "styled-components";
import tw from "twin.macro";

const TextArea = styled.textarea`
  ${tw`
    p-10 border rounded-sm resize-none transition-colors duration-300
    md:px-15
  `}
  
  &::placeholder {
    ${tw`text-second opacity-50`}
  }
`;

const FormTextarea = ({ register, name, required = true, errors, rows }: FormFieldProps) => {
  const intl = useIntl();

  return (
    <TextArea
      id={name}
      name={name}
      rows={rows}
      className={`${errors[name] ? 'border-error': 'border-input'}`}
      placeholder={intl.formatMessage({ id: `Form.Placeholders.${name}` })}
      ref={register({ required: required })}
    />
  );
};

export default FormTextarea;
