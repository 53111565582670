import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

import ArrowPrimary from "@components/arrows/primary";
import ArrowRed from "@components/arrows/red";

const Wrapper = tw.div`relative`;

const ArrowWrapper = styled.div`
  ${tw`
    absolute! hidden transform rotate-90
    lg:block lg:top-660 lg:left-80 lg:w-150
    xl:left-540
  `}
  
  svg {
    ${tw`right-0 w-full lg:-rotate-90`}
  }
`;

const ArrowCircleWrapper = tw.div`
  absolute! hidden transform rotate-90
  lg:block lg:top-170 lg:-left-65 lg:w-200
`;

const ContactImages = () => (
  <Wrapper>
    <ArrowWrapper>
      <ArrowPrimary />
    </ArrowWrapper>
    <ArrowCircleWrapper>
      <ArrowRed />
    </ArrowCircleWrapper>
  </Wrapper>
);

export default ContactImages;
