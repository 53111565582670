import React from "react";
import { regExps } from "@utils/variables";
import { FormFieldProps } from "@utils/types";

import FormInputWrapper from "@components/form/input-wrapper";

const StepPersonDetails = ({ register, errors }: FormFieldProps) => (
  <>
    <FormInputWrapper register={register} errors={errors} name={'name'} />
    <FormInputWrapper register={register} errors={errors} name={'email'} pattern={regExps.email} />
    <FormInputWrapper register={register} errors={errors} name={'tel'} pattern={regExps.tel} required={false} />
  </>
);

export default StepPersonDetails;
