import React from "react";
import { useTranslation } from "react-i18next";
import { FormNamesTypes } from "@utils/types";
import tw from "twin.macro";

const Label = tw.label`
  font-pangramBold mb-5 text-sm text-second leading-20 -tracking-021 opacity-50
  md:mb-16
`;

const FormLabel = ({ name }: { name: FormNamesTypes }) => {
  const { t } = useTranslation();

  return (
    <Label htmlFor={name}>
      {t(`Form:Labels:${name}`)}
    </Label>
  );
};

export default FormLabel;
