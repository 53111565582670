import React from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";

const StepInfo = tw.div`mb-20`;

const StepNumber = tw.div`
  mb-5 text-xs text-portfolioText -tracking-021
  lg:mb-16 lg:text-sm
`;

const StepDesciption = tw.div`
  mb-10 text-md -tracking-028
  lg:text-4xl lg:leading-56
`;

const FormStepInfo = ({ stepNumber }: { stepNumber: number }) => {
  const { t } = useTranslation();

  return (
    <StepInfo>
      <StepNumber>
        {t('Form:Step', { number: stepNumber })}
      </StepNumber>
      <StepDesciption>
        {t(`Form:Steps:${stepNumber}1`)}
        <br/>
        {t(`Form:Steps:${stepNumber}2`)}
        <br/>
        {t(`Form:Steps:${stepNumber}3`)}
      </StepDesciption>
    </StepInfo>
  );
}

export default FormStepInfo;
