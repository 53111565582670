import React, { useLayoutEffect, useState } from "react"
import { motion, useTransform } from "framer-motion"
import { useTranslation } from "react-i18next";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import Img from "@images/scroll.svg";
import tw from "twin.macro";

import { blink } from "@utils/keyframes";

type ButtonProps = {
  text?: string,
  scroll: any,
  width: number
};

const Arrow = () => (
  <Img className="w-8 transform -rotate-90" />
);

const Button = styled(motion.button)`
  transition-timing-function: ease-out;
  ${tw`
    font-pangramBold relative h-50 min-w-280 mx-auto py-3 text-white -tracking-013 bg-orange
    rounded-full overflow-hidden transition-colors duration-300 focus:outline-none
    md:w-400
  `}
  
  &::before {
    content: '';
    position: absolute;
    right: 0;
    top: 0px;
    width: 44px;
    height: 44px;
    border-radius: 9999px;
    transition: all .3s;
    margin: 2px 2px;
    background-color: #fdfdfd;
    width: 46px;
    height: 46px;
    transition: 0.3s all;
    transition-delay: 0.1s;
  }
  
  .hover-effect {
    content: attr(data-text);
    transform-origin: -25% 50%;
    transform: rotate3d(0,0,1,45deg);
    transition-timing-function: ease-in;
    position: absolute;
    top: 0;
    left: 50%;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 25px;
    padding-right: 25px;
    color: #f06140;
    transition-property: transform;
    transition-duration: 300ms;
    transition-delay: 100ms;
  }
  
  &:hover {
    &::before {
      width: calc(100% - 4px);
      transition-delay: 0s;
    }
    
    .hover-effect {
      transform: rotate3d(0,0,1,0deg) translateX(-50%);
      transition-timing-function: ease-out;
      transition-delay: 0.2s;
    }
  }
  
  ${up('lg')} {
    .svg-color {
      stroke: #F06140;

      polyline {
        transform: translateY(-3px);
        transition: transform .3s .2s;
      }
    
      line {
        transform: scaleY(0);
        transition: transform .3s .2s;
      }
    }

    &:hover {
      .svg-color {
        polyline {
          transform: translateY(0);
          transition: transform .3s .4s;
        }
      
        line {
          transform: scaleY(1);
          transition: transform .3s .4s;
        }
      }
      
      .dot {
        animation: ${blink} 1.4s both infinite;
        
        &:nth-child(2) {
          animation-delay: .2s;
        }
        
        &:nth-child(3) {
          animation-delay: .4s;
        }
      }
    }
  }
`;

const OvalWhite = tw.span`
  absolute top-1/2 right-0
  flex justify-center items-center
  w-44 h-44
  ml-15 mr-4
  text-primary bg-white rounded-full
  transform -translate-y-1/2
`;

const Submit = ({ text = 'Buttons:Send request', width, scroll }: ButtonProps) => {
  const { t } = useTranslation();

  const submitRef = React.useRef();

  const [sectionStart, setSectionStart] = useState(0);
  const [sectionEnd, setSectionEnd] = useState(.5);

  const y = width && useTransform(scroll, [sectionStart, sectionEnd + 180], [120, 0]);
  const opacity = width && useTransform(scroll, [sectionStart + 40, sectionEnd + 150], [0, 1]);

  useLayoutEffect(() => {
    if (!submitRef.current) return;

    const offsetStart = submitRef.current.offsetTop - window.innerHeight;
    const offsetEnd = offsetStart + submitRef.current.clientHeight;

    setSectionStart(offsetStart);
    setSectionEnd(offsetEnd);
  });

  return (
    <Button ref={submitRef} type="submit" style={ width >= 1024 && { y, opacity }}>
      {t(`${text}`)}
      <OvalWhite>
        <Arrow />
      </OvalWhite>
      <span className="hover-effect">
        {t(`${text}`)}
      </span>
    </Button>
  );
};

export default Submit;
