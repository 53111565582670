import { keyframes } from "styled-components";

export const scrollMouseAnimation = keyframes`
  0% { opacity: 0; }
  10% { opacity: 0; }
  30% { transform: translateY(0); opacity: 1; }
  40% { transform: translateY(0); opacity: 1; }
  100% { transform: translateY(18px) scaleY(.2); opacity: 0;}
`;

export const dot = keyframes`
  0% { transform: scale(0); }
  3% { transform: scale(.6); }
  6.1% { transform: scale(.2); }
  9.1% { transform: scale(1.2); }
  12.2% { transform: scale(1); }
  69.2% { transform: scale(1); }
  73.8% { transform: scale(.8); }
  76.8% { transform: scale(1.2); }
  81.4% { transform: scale(0); }
  100% { transform: scale(0); }
`;

export const arrow = keyframes`
  0% { transform: scale(0) rotate(-8deg); }
  12.2% { transform: scale(1) rotate(0); }
  69.2% { transform: scale(1); }
  81.4% { transform: scale(0) rotate(-8deg); }
  100% { transform: scale(0); }
`;

export  const line = keyframes`
  0% { stroke-dashoffset: 1000; }
  12.2% { stroke-dashoffset: 0; }
  70.7% { stroke-dashoffset: 0; }
  76.8% { stroke-dashoffset: 1000; }
  100% { stroke-dashoffset: 1000; }
`;

export const brandsAnimation = keyframes`
  0% { transform: scale(1) rotate(0); opacity: 1; }
  16% { transform: scale(1) rotate(0); opacity: 0; }
  18% { transform: scale(0) rotate(-10deg); opacity: 0; }
  23% { transform: scale(0) rotate(-10deg); opacity: 1; }
  37% { transform: scale(1) rotate(0); opacity: 1; }
  100% { transform: scale(1) rotate(0); opacity: 1; }
`;

export const bgSize = keyframes`
  from { background-size: 100% 0%; }
  to { background-size: 100% 100%; }
`;

export const blink = keyframes`
  0% { opacity: .2; }
  20% { opacity: 1; }
  100% { opacity: .2; }
`;

export const letterPathLength = (strokeDashoffset: number) => keyframes`
  0% { stroke-dashoffset: 1000; }
  3% { stroke-dashoffset: 1000; }
  12.2% { stroke-dashoffset: ${strokeDashoffset}; }
  66% { stroke-dashoffset: ${strokeDashoffset}; }
  72.2% { stroke-dashoffset: 1000; }
  100% { stroke-dashoffset: 1000; }
`;
