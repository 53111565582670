import React from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";

import LetterRight from "@components/svg/letter-right";
import ArrowPrimary from "@components/arrows/primary";
import Details from "@components/svg/details-simple";

const Wrapper = tw.div`
  relative flex flex-wrap mx-40 mb-60
  sm:mb-80
  md:justify-between md:mt-90 md:mx-0 md:mb-100
  lg:justify-start lg:mt-175 lg:mb-180
`;

const Character = tw.div`
  font-pangramBold hidden
  lg:block lg:min-w-40 lg:mt-10 lg:mr-10 lg:text-lg lg:text-second lg:leading-32 lg:-tracking-03
`;

const Heading = styled.div`
  ${tw`
    relative z-1 w-full mb-10 text-xl leading-33 -tracking-016
    sm:w-320
    lg:w-520 lg:text-2xl lg:leading-60 lg:-tracking-024
  `}
  
  br {
    ${tw`hidden sm:block`}
  }
`;

const Description = styled(motion.div)`
  ${tw`
    relative z-1 max-w-290 text-sm text-second leading-20 -tracking-021
    sm:w-280
    md:mt-12 md:max-w-none
    lg:top-120 lg:opacity-0
    lg:w-360 lg:ml-auto lg:text-md lg:leading-30 lg:-tracking-027
    xl:ml-200
  `}
  
  span {
    ${tw`text-portfolioText`}
  }
  
  br {
    ${tw`hidden sm:block`}
  }
`;

const LetterWrapper = tw.div`
  absolute! hidden
  sm:block sm:top-40 sm:left-400 sm:w-280
  md:-top-100 md:left-350 md:w-250
  lg:-top-154 lg:left-455 lg:w-380
  xl:left-565
`;

const DetailsWrapper = styled.div`
  ${tw`
    absolute! hidden
    md:block md:-top-110 md:-left-50
    lg:-top-230 lg:-left-230 lg:w-560
  `}
  
  svg {
    ${tw`top-0 left-0`}
  }
`;

const ArrowWrapper = styled.div`
  ${tw`
    absolute! hidden transform rotate-90
    md:block md:top-130 md:-right-70 md:w-100
    lg:top-auto lg:-bottom-10 lg:left-auto lg:-right-160 lg:w-190
  `}
  
  svg {
    ${tw`right-0 w-full lg:-rotate-90`}
  }
`;

const ContactHeader = ({ width }: { width: number }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <motion.div
        className="relative flex lg:opacity-0 lg:top-280"
        animate={ width >= 1024 && 'visible'}
        transition={{
          opacity: { duration: .542, ease: 'linear' },
          default: { duration: .875, delay: .542, ease: [.84, 0, .16, 1] }
        }}
        variants={{ 'visible': { y: -280, opacity: 1 } }}
      >
        <Character>
          {t('Description Character')}
        </Character>
        <Heading>
          {t('Form:Heading1')}
          <br/>
          {t('Form:Heading2')}
        </Heading>
      </motion.div>
      <Description
        initial={ width >= 1024 && 'hidden'}
        animate={ width >= 1024 && 'visible' }
        transition={{
          y: { duration: .917, delay: .5, ease: [.88, .14, .12, .86] },
          opacity: { duration: .458, delay: .75, ease: 'linear' },
          rotate: { duration: .917, delay: .5, ease: [.88, .14, .12, .86] }
        }}
        variants={{
          'visible': { y: -120, opacity: 1, rotate: 0 },
          'hidden': { rotate: -8 }
        }}
      >
        {t('Form:Description1')}
        <span>
          {t('Form:Description2')}
          <br/>
          {t('Form:Description3')}
        </span>
        {t('Form:Description4')}
      </Description>
      <LetterWrapper>
        <LetterRight />
      </LetterWrapper>
      <DetailsWrapper>
        <Details />
      </DetailsWrapper>
      <ArrowWrapper>
        <ArrowPrimary />
      </ArrowWrapper>
    </Wrapper>
  );
};

export default ContactHeader;
