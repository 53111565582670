import React from "react";
import { useForm } from "react-hook-form";
import { FormNamesTypes } from "@utils/types";
import { motion } from "framer-motion"
import { navigate } from "gatsby-link";
import tw from "twin.macro";

import FormStep from "@components/form/step";
import Submit from "@components/buttons/submit";
import StepDescription from "@components/form/step-description";
import StepPersonDetails from "@components/form/step-person-details";
import StepFile from "@components/form/step-file";

type FormData = {
  name: FormNamesTypes,
  description: string,
  email: string,
  tel: string,
  file: File
};

const Form = tw.form`
  flex flex-col
  lg:mt-82 lg:mb-108
  xl:ml-110
`;

const ContactForm = ({ scroll, width }: { scroll: any, width: number }) => {
  const { register, handleSubmit, errors, setError, clearErrors } = useForm<FormData>();
  const [file, setFile] = React.useState<File[]>([]);

  const onSubmit = (data: any) => {
    const path = typeof window !== 'undefined' ? window.location.pathname : '';
    const isEngVersion = path.split('/').find(x => x === 'en');
    let test = new FormData();

    for (const [key, value] of Object.entries(data)) {
      test.append(key, value)
    }
    test.append('file', file[0]);

    if (isEngVersion) {
      fetch('https://hooks.zapier.com/hooks/catch/2133685/oe9zq8x', {
        method: 'POST',
        body: test
      })
        .then(() => navigate('/en/thank-you'))
    } else {
      fetch('https://hooks.zapier.com/hooks/catch/2133685/o4kipf7', {
        method: 'POST',
        body: test
      })
        .then(() => navigate('/dziekujemy'))
    }
  };

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      name="test"
      data-netlify="true"
    >
      <motion.div
        className="lg:opacity-0"
        initial={ width >= 1024 && 'hidden'}
        animate={ width >= 1024 && 'visible' }
        transition={{
          duration: .6,
          delay: 1.4,
          ease: 'linear'
        }}
        variants={{
          'visible': { opacity: 1 },
          'hidden': { opacity: 0 }
        }}
      >
        <FormStep scroll={ scroll } width={ 0 } stepNumber={1}>
          <StepDescription name={'description'} register={register} errors={errors} />
        </FormStep>
        <FormStep scroll={ scroll } width={ width } stepNumber={2}>
          <StepPersonDetails register={register} errors={errors} />
        </FormStep>
        <FormStep scroll={ scroll } width={ width } stepNumber={3}>
          <StepFile errors={errors} setError={setError} clearErrors={clearErrors} setFile={setFile} />
        </FormStep>
      </motion.div>
      <Submit scroll={ scroll } width={ width } />
    </Form>
  );
};

export default ContactForm;
