import React, { useState } from "react";
import { FormErrorsTypes, FormNamesTypes } from "@utils/types";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import Img from "@images/dropzone.svg";
import tw from "twin.macro";

import { StyledFieldWrapper } from "@components/form/field-wrapper";
import FormLabel from "@components/form/label";
import FormError from "@components/form/error";
import FormNotification from "@components/form/notification";

type FormStepProps = {
  errors: FormErrorsTypes,
  setError: Function,
  clearErrors: Function,
  setFile: Function
};

const DragNDrop = styled.div<{ error: boolean }>`
  ${tw`
    relative flex flex-col justify-between items-center py-33 px-40 border border-dotted
    rounded-sm text-center cursor-pointer transition-colors duration-300 focus:outline-none
  `}
  
  ${({ error }) => error ? tw`border-error` : tw`border-input`}
`;

const DragNDropText = tw.div`mt-10 text-sm text-second leading-21 -tracking-021`;

const FileInfo = styled.div`
  animation: fadeIn ease .3s;
  ${tw`mt-6 text-xs text-right`}
`;

const StepFile = ({ errors, setError, clearErrors, setFile }: FormStepProps) => {
  const [message, setMessage] = useState<string | boolean>(false);
  const { t } = useTranslation();

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    maxSize: 2000000,
    multiple: false,
    onDropRejected: files => {
      setError('file', { type: 'manual', message: files[0].errors[0].code });
      setNotification('error');
    },
    onDropAccepted: files => {
      clearErrors('file');
      setFile(files);
      setNotification('success');
    }
  });

  const setNotification = (value: string) => {
    setMessage(value);

    setTimeout(() => {
      setMessage(false);
    }, 2500);
  };

  const files = acceptedFiles.map((file) => (
    <FileInfo key={file.path}>{file.path}</FileInfo>
  ));

  return (
    <>
      {message && <FormNotification message={message} />}
      <StyledFieldWrapper>
        <div onClick={open} className="mb-16">
          <FormLabel name={'file'} />
        </div>
        <DragNDrop error={errors.file} {...getRootProps()}>
          <input name="file" {...getInputProps()} />
          <Img />
          <DragNDropText>
            {t('Form:Placeholders:file')}
          </DragNDropText>
        </DragNDrop>
        <FormError errors={errors} name={'file'} />
        {files}
      </StyledFieldWrapper>
    </>
  );
};

export default StepFile;
