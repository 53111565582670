import React, { ReactNode, useLayoutEffect, useState } from "react";
import { motion, useTransform } from "framer-motion"
import tw from "twin.macro";

import FormStepInfo from "@components/form/step-info";

type FormStepProp = {
  children: ReactNode,
  stepNumber: number,
  scroll: any,
  width: number
};

const StepWrapper = tw(motion.div)`
  mb-20 px-40 border-b-2 border-footerLight last:border-0
  md:px-0
  lg:flex lg:justify-between lg:mb-75 lg:pb-55 lg:last:mb-0
`;

const FormStepFields = tw(motion.div)`flex flex-col w-full max-w-515`;

const FormStep = ({ children, stepNumber, scroll, width }: FormStepProp) => {
  const stepRef = React.useRef();

  const [sectionStart, setSectionStart] = useState(0);
  const [sectionEnd, setSectionEnd] = useState(.5);

  const opacity = width && useTransform(scroll, [sectionStart, sectionEnd], [0, 1]);
  const left = width && useTransform(scroll, [sectionStart, sectionEnd + 100], [-80, 0]);
  const right = width && useTransform(scroll, [sectionStart, sectionEnd + 100], [80, 0]);

  useLayoutEffect(() => {
    if (!stepRef.current) return;

    const offsetStart = stepRef.current.offsetTop - window.innerHeight;
    const offsetEnd = offsetStart + stepRef.current.clientHeight;

    setSectionStart(offsetStart + 100);
    setSectionEnd(offsetEnd);
  });

  return (
    <StepWrapper ref={stepRef} style={ width >= 1024 && { opacity }}>
      <motion.div style={ width >= 1024 && { x: left }}>
        <FormStepInfo stepNumber={stepNumber} />
      </motion.div>
      <FormStepFields style={ width >= 1024 && { x: right }}>
        {children}
      </FormStepFields>
    </StepWrapper>
  );
};

export default FormStep;
