import React, { ReactNode } from "react";
import { FormNamesTypes } from "@utils/types";
import tw from "twin.macro";

import FormLabel from "@components/form/label";

type FieldWrapperProps = {
  children: ReactNode,
  name: FormNamesTypes
};

export const StyledFieldWrapper = tw.div`relative flex flex-col w-full pb-28`;

const FormFieldWrapper = ({ children, name }: FieldWrapperProps) => (
  <StyledFieldWrapper>
    <FormLabel name={name} />
    { children }
  </StyledFieldWrapper>
);

export default FormFieldWrapper;
