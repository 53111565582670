import React from "react";
import { FormFieldProps } from "@utils/types";
import { useIntl } from 'gatsby-plugin-intl';
import styled from "styled-components";
import tw from "twin.macro";

const Input = styled.input`
  ${tw`
    h-52 p-10 border rounded-sm transition-colors duration-300
    md:px-15
  `}
  
  &::placeholder {
    ${tw`text-second opacity-50`}
  }
`;

const FormInput = ({ register, name, required = true, pattern, errors, ...other }: FormFieldProps) => {
  const intl = useIntl();

  return (
    <Input
      id={name}
      name={name}
      className={`${errors[name] ? 'border-error': 'border-input'}`}
      placeholder={intl.formatMessage({ id: `Form.Placeholders.${name}` })}
      ref={register({
        required: required,
        pattern: pattern
      })}
      {...other}
    />
  );
};

export default FormInput;
