import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";

const Notification = styled.div<{ success: boolean }>`
  animation: moveOpen ease 2.5s forwards;
  ${tw`
    fixed top-0 left-1/2
    min-w-280 py-10
    text-white text-center -tracking-013
    border rounded-full z-50
  `}

  ${({ success }) => success
    ? tw`bg-success border-successBorder`
    : tw`bg-fileError border-errorBorder`}
`;

const FormNotification = ({ message }: { message: string | boolean }) => {
  const { t } = useTranslation();

  return (
    <Notification success={message === 'success'}>
      {t(`Form:File:${message}`)}
    </Notification>
  );
};

export default FormNotification;
