import React from "react";
import { FormFieldProps } from "@utils/types";

import FormFieldWrapper from "@components/form/field-wrapper";
import FormError from "@components/form/error";
import FormTextarea from "@components/form/textarea";

const StepDescription = ({ register, errors, name }: FormFieldProps) => (
  <FormFieldWrapper name={name}>
    <FormTextarea register={register} name={name} errors={errors} rows={7} />
    <FormError errors={errors} name={name} />
  </FormFieldWrapper>
);

export default StepDescription;
