import React from "react";
import { FormFieldProps } from "@utils/types";

import FormFieldWrapper from "@components/form/field-wrapper";
import FormInput from "@components/form/input";
import FormError from "@components/form/error";

const FormInputWrapper = ({ register, errors, name, required = true, pattern }: FormFieldProps) => (
  <FormFieldWrapper name={name}>
    <FormInput
      register={register}
      name={name}
      errors={errors}
      required={required}
      pattern={pattern}
    />
    <FormError errors={errors} name={name} />
  </FormFieldWrapper>
);

export default FormInputWrapper;
