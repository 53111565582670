import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { useViewportScroll } from "framer-motion";
import useWindowSize from "@utils/useWindowSize";

import Layout from '@layouts/index';
import SEO from "@components/seo";
import Header from "@components/contact";
import Images from "@components/contact/images";
import Form from "@components/form";

const ContactPage = () => {
  const intl = useIntl();
  const { scrollY } = useViewportScroll();
  const { width } = useWindowSize();

  return (
    <Layout>
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: 'SEO.Title.Contact' })}
        description={intl.formatMessage({ id: 'SEO.Description.Contact' })}
      />
      <Header width={ width } />
      <Images />
      <Form scroll={ scrollY } width={ width } />
    </Layout>
  );
};

export default ContactPage;
