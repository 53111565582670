import React from "react";
import styled, { keyframes } from "styled-components";
import tw from "twin.macro";

import { arrowAnimationDuration, arrowPrimaryDelay } from "@utils/variables";

const arrowAnimation = keyframes`
  0% { transform: scale(0) translateZ(0); }
  2.4% { transform: scale(-.2) translateZ(0); }
  8.4% { transform: scale(1) translateZ(0); }
  54% { transform: scale(1) translateZ(0); }
  61.2% { transform: scale(0) translateZ(0); }
  100% { transform: scale(0) translateZ(0); }
`;

const pathLength = keyframes`
  0% { stroke-dashoffset: 1000; }
  4.8% { stroke-dashoffset: 1000; }
  9.6% { stroke-dashoffset: 930; }
  58.8% { stroke-dashoffset: 930; }
  63.6% { stroke-dashoffset: 1000; }
  100% { stroke-dashoffset: 1000; }
`;

const pathLengthShort = keyframes`
  0% { stroke-dashoffset: 1000; }
  2.4% { stroke-dashoffset: 1000; }
  12% { stroke-dashoffset: 750; }
  54% { stroke-dashoffset: 750; }
  61.2% { stroke-dashoffset: 1000; }
  100% { stroke-dashoffset: 1000; }
`;

const StyledImg = tw.svg`
  absolute! top-0 right-18 w-55 transform -rotate-90
  xl:w-160 xl:-right-90 xl:rotate-0
`;

const StyledArrow = styled.polygon`
  transform: scale(0);
  transform-origin: 21px 32px;
  animation: ${arrowAnimation} ${arrowAnimationDuration}s ${arrowPrimaryDelay}s infinite;
`;

const StyledLine = styled.path<{ shortAnimation?: boolean }>`
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: ${arrowAnimationDuration}s ${arrowPrimaryDelay}s infinite;
  animation-name: ${({ shortAnimation }) => shortAnimation ? pathLengthShort : pathLength };
`;

const ArrowPrimary = () => (
  <StyledImg viewBox="0 0 150 170">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g stroke="#761113" strokeWidth="0.5" strokeLinejoin="round">
        <StyledLine shortAnimation d="M0,160 C0,160 117.000886,109.243816 143,27" />
        <StyledLine d="M86,130 C86,130 117.877493,110.218843 134,81" />
      </g>
      <g transform="translate(112.000000, 0.000000)">
        <StyledArrow fill="#99A2D1" points="0.6742 55.051225 41.354 63.952975 40.3152 0.724225" />
      </g>
    </g>
  </StyledImg>
);

export default ArrowPrimary;
